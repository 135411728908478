import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container'
import quote from '../assets/images/quote.png'


const Testimonials = props => {

  const { testimonials } = props
  return(
  <Carousel id="testimonials"
  nextIcon={<span aria-hidden="true" className="custom-carousel-next-icon" />
}
prevIcon={<span aria-hidden="true" className="custom-carousel-prev-icon" />
}
  >
    {testimonials && testimonials.map((testimonial, index) => {
      return(
    <Carousel.Item key={index}>
      <img
        className="d-block w-100"
        src={testimonial.node.image.file.url}
        alt="First slide"
      />
      <Container>
      <Carousel.Caption className="vertical-middle">
          <img className="testimonial-quote" src={quote}/>
        <p>{testimonial.node.quote}</p>
        <h3>{testimonial.node.name}</h3>
        <p>{testimonial.node.title}</p>
      </Carousel.Caption>
      </Container>
    </Carousel.Item>
      )
    }
    )
}
  </Carousel>
  )
}
export default Testimonials

