import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { StaticQuery } from 'gatsby'

export default () => (
    <StaticQuery
    query={graphql`
      query CTAQuery {
        allContentfulJobListingLink {
          edges {
            node {
              link
            }
          }
        }
      }
    `}
    render={data => (
    <Row id="cta-bar" >
        <Col >
            <Container >
                <Row >
                    <Col md="8" className="my-auto">
                        <p>Are you ready to make an impact?</p>
                    </Col>
                    <Col md="4">
                    <a href={data.allContentfulJobListingLink.edges[0].node.link} target="_new"> <Button className="cta-button">JOB LISTINGS</Button></a>
                    </Col>
                </Row>
            </Container>
        </Col>
    </Row>
    )}
    />
)



